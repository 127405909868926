import React from "react";

import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "./styles";

const SliderWrapper = ({
  children,
  className,
  autoplay = true,
  speed = 300,
  loop = true,
}) => {
  const settings = React.useMemo(
    () => ({
      dots: true,
      infinite: loop,
      speed: speed,
      slidesToShow: 1,
      autoplay: Boolean(autoplay),
      autoplaySpeed: typeof autoplay === "boolean" ? 5000 : autoplay,
      dotsClass: "dots-custom",
    }),
    [autoplay, loop, speed]
  );

  const onsubmit = () => {
    window.open("https://naver.me/GWJyzblU", "_blank", "noopener, noreferrer");
  };
  return (
    <>
      <S.SliderContainer>
        <Slider {...settings}>
          <S.Container>
            <S.TextBold>
              <span>급한 대출일수록</span>
              <span>믿을 수 있어야 하니까</span>
            </S.TextBold>
            <S.Text>
              <span>어떠한 상황에도</span>
              <span>전문적인 대출 제안을 드립니다</span>
            </S.Text>
          </S.Container>
          <S.Container>
            <S.TextBold>
              <span>한도는 UP</span>
              <span>금리는 DOWN</span>
            </S.TextBold>
            <S.Text>
              <span>고객님의 상황에 맞는</span>
              <span>최적의 상품을 제안해 드립니다</span>
            </S.Text>
          </S.Container>
        </Slider>
        <S.ImgContainer>
          <img src="/silder_mobile_1.png" alt="슬라이드_첫번째이미지" />
        </S.ImgContainer>
        <S.ButtonContainer>
          <button onClick={onsubmit}>상담신청</button>
        </S.ButtonContainer>
      </S.SliderContainer>
    </>
  );
};

export default SliderWrapper;
