import styled from "styled-components";

export const SliderContainer = styled.section`
  position: relative;

  overflow-x: hidden;
  .dots-custom {
    list-style: none;
    display: flex !important;
    justify-content: center !important;
    padding: 0;
    margin: 0;
  }

  .dots-custom li {
    &:first-of-type {
      margin-right: 8px;
      transform-origin: right !important;
    }
    display: inline-block;

    cursor: pointer;
  }

  .dots-custom li button {
    width: 8px;
    height: 8px;
    color: transparent;
    cursor: pointer;
    background: #d1d1d1;
    border: none;
    border-radius: 4px;
  }

  .dots-custom li.slick-active button {
    width: 24px;
    background-color: #a3ba1c;
    transition:
      width 1s,
      background-color 1s;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 280px;

  padding: 0 20px;
  display: flex;
  padding-top: 60px;
`;

export const TextBold = styled.div`
  color: #a3ba1c;
  font-size: 40px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-family: Pretendard;
`;

export const Text = styled.div`
  color: #333;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 700;
`;

export const ImgContainer = styled.div`
  img {
    width: 404px;
    height: 320px;
  }
  position: absolute;
  bottom: 5px;
  right: 20px;
  z-index: -1;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 20px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 256px;
    height: 54px;
    background-color: #a3ba1c;
    border: none;
    border-radius: 100px;
    color: #fff;
    font-size: 32px;
    font-weight: 700;

    img {
      width: 30px;
      height: 30px;
    }
  }
`;
