import React from "react";
import { Titles_PC_TYPE, 탭메뉴_TYPE } from "../../../contants/defines";
import * as S from "./styles";

const TabMenuContainer = () => {
  const [activeTab, setActiveTab] = React.useState(1);

  const handleTabClick = (key) => {
    setActiveTab(key);
  };

  const 상담신청_폼_클릭 = () => {
    window.open("https://naver.me/GWJyzblU", "_blank", "noopener, noreferrer");
  };
  const 카톡상담_신청 = () => {
    window.open(
      "https://pf.kakao.com/_xgAufxb/chat",
      "_blank",
      "noopener, noreferrer"
    );
  };

  return (
    <>
      <S.Container>
        {Titles_PC_TYPE.map(({ key, title, subTitle }, index) => (
          <>
            <S.TabMenu
              key={index}
              active={activeTab === key}
              onClick={() => handleTabClick(key)}
            >
              {title}
            </S.TabMenu>
          </>
        ))}
      </S.Container>
      <S.Test>
        <S.TestPhone onClick={상담신청_폼_클릭}>
          <img src="/simple_apply.png" alt="상담신청" />
          상담신청
        </S.TestPhone>
        <S.TestKakao onClick={카톡상담_신청}>
          <img src="/simple_kakao_apply.png" alt="카톡상담" />
          카톡상담
        </S.TestKakao>
      </S.Test>
      {탭메뉴_TYPE.map(
        ({ key, subTitle, img, imgalt, oklabel, nolabel }, index) => {
          const select = activeTab === key;
          return (
            <>
              {select && (
                <>
                  <S.SubTitle key={index}>{subTitle}</S.SubTitle>
                  <div style={{ display: "flex" }}>
                    <S.SubImg>
                      <img src={img} alt={imgalt} />
                    </S.SubImg>
                    <S.ContentWrapper>
                      <S.ContentLabel>
                        {oklabel.map((label, labelIndex) => (
                          <S.LabelWrapper>
                            <img src="/check_m.png" alt="체크_이미지" />
                            <span key={labelIndex}>{label}</span>
                            <img src="/ok_m.png" alt="ok_이미지" />
                          </S.LabelWrapper>
                        ))}
                        {nolabel.map((label, labelIndex) => (
                          <S.LabelWrapper>
                            <img src="/check_m.png" alt="체크_이미지" />
                            <span key={labelIndex}>{label}</span>
                            <img src="/no_m.png" alt="no_이미지" />
                          </S.LabelWrapper>
                        ))}
                      </S.ContentLabel>
                    </S.ContentWrapper>
                  </div>
                </>
              )}
            </>
          );
        }
      )}
    </>
  );
};

export default TabMenuContainer;
