import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0 20px 0;
  button {
    border: 1px solid #bec3cc;
    width: calc(100% - 40px);
    background-color: #fff;
    margin: 0 auto;
    border-radius: 80px;
    font-size: 20px;
    font-weight: 700;
    color: #6d7582;
    p {
      line-height: 10px;
    }
  }
  font-family: Pretendard;
`;

export const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 75px 0 94px;
`;

export const Item = styled.div`
  width: 33%;
  text-align: center;
  border-right: 1px solid #bec3cc;
  &:last-child {
    border-right: none;
  }
`;

export const SubTitle = styled.div`
  color: #a3ba1c;
  font-size: 36px;
  font-weight: 400;
  font-family: JalnanGothic;
  padding-bottom: 20px;
`;
