export const 주택담보_TYPE = [
  {
    title: "주택담보대출",
  },
];

export const 신속_정확_믿음_TYPE = [
  {
    title: "신속",
    label: ["365일 24시간", "언제라도 상담 가능"],
  },
  {
    title: "정확",
    label: ["10년이상 경력의", "전문상담사 대기중"],
  },
  {
    title: "믿음",
    label: ["2021년 대한민국", "고객감동브랜드대상"],
  },
];

export const 개인정보처리_TYPE = [
  {
    label: "1. 총칙",
    value: [
      "(1) 개인정보란 크레딧몰을 이용하는 개인에 관한 정보로서 성명, 연락처 등의 사항에 의하여 개인을 식별할 수 있는 정보를 말합니다.",
      "(2) 크레딧몰(이하 '회사'라 함)는 귀하의 개인정보보호를 매우 중요시하며, '정보통신망 이용촉진 및 정보보호 등에 관한 법률'상의 개인정보 보호 규정 및 방송통신위원회 (미래창조과학부)가 제정한 '개인정보취급방침'을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다",
      "(3) 회사는 개인정보취급방침을 상담 접수시 화면에 공개함으로서, 귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.",
    ],
  },
  {
    label: "2. 개인정보 수집 항목",
    value: [
      "회사는 상담을 위해 아래와 같은 정보를 수집하고 있습니다. 인터넷 홈페이지는 별도의 회원가입 절차 없이 컨텐츠에 자유롭게 접근 하실 수 있습니다.",
      "(1) 필수항목 : 성명, 전화번호",
      "(2) 선택항목 : 부동산정보, 대출금액, 카카오톡아이디, 네이트온아이디 등 대출상담 목적에 관한 정보",
      "(3) 수집방법 : 홈페이지 상담접수 및 전화 상담접수",
    ],
  },
  {
    label: "3. 개인정보 수집에 대한 동의",
    value: [
      "회사는 귀하께서 회사의 인터넷 홈페이지 개인정보취급방침 내용에 대해 '동의함' 버튼을 클릭 하실 수 있는 절차를 마련하여, '동의함' 버튼을 클릭시 개인정보 수집에 동의하는 것으로 봅니다.",
    ],
  },
  {
    label: "4. 개인정보의 수집목적 및 이용목적",
    value: [
      "회사는 다음과 같은 목적을 위해 개인정보를 수집 하고 있습니다.",
      "성명, 전화번호, 부동산정보, 대출금액, 카카오톡아이디, 네이트온아이디, 대출상담 목적에 관한 기타 선택항목 : 금리비교서비스 및 대출 상담 목적",
    ],
  },
  {
    label: "5. 개인정보의 보유 및 이용 기간",
    value: [
      "귀하의 개인정보는 개인정보의 수집 또는 이용 목적이 달성되면 파기됩니다. 단, 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 예외로 합니다. 이 경우 해당 개인정보는 보관의 목적으로만 관리합니다.",
      "- 보존 항목 : 성명, 전화번호, 부동산정보, 대출금액, 카카오톡아이디, 네이트온아이디",
      "- 보존 근거 : 서비스 이용의 혼선 방지, 불법적 사용자에 대한 관련 기관 수사협조",
      " 보존 기간 : 1년",
      "- 보존 항목 : 서비스 이용기록, 접속 로그, 접속 IP 정보",
      "- 보존 근거 : 통신보호비밀법",
      "- 보존 기간 : 3개월",
    ],
  },

  {
    label:
      "6. 개인정보 수집·이용·제공에 대한 동의철회, 개인정보파기 절차 및 방법",
    value: [
      "상담접수 등을 통해 개인정보의 수집, 이용, 제공에 대해 귀하께서 동의하신 내용을 언제든지 철회하실 수 있습니다. 동의철회는 개인정보관리 책임자에게 서면, 전화, E-mail 등으로 연락하시면 즉시 개인정보를 서버에서 추출하여 법령규정에 의해 보관하는 이외의 정보를 기술적으로 재생할 수 없도록 삭제하며, 해당 관리자가 확인토록 합니다.",
    ],
  },
  {
    label: "7. 개인정보 제공",
    value: [
      "회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.",
      "(1) 이용자가 사전에 제3자 제공 및 공개에 동의한 경우",
      "(2) 법령등에 의해서 제공이 요구되는 경우",
      "(3) 개인을 식별하기에 특정할 수 없는 상태로 가공하여 이용하는 경우",
    ],
  },

  {
    label: "8.개인정보를 제공 받는 자",
    value: ["등록된 대부업 및 대부중개업체에 한 함"],
  },

  {
    label: "9. 개인정보관리 책임자",
    value: [
      "회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.",
      "- 성명 : 박병주",
      "- 전화번호 : 1644-2824",
      "- 이메일 : just4uto@hanmail.net",
    ],
  },

  {
    label: "10. 의견수렴 및 불만처리",
    value: [
      "(1)민원처리센터 전화번호 : 1644-2824",
      "(2) 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 정보보호마크 인증위원회, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버범죄수사대 등으로 문의하실 수 있습니다.",
    ],
  },

  {
    label: "개인정보침해신고센터",
    value: ["- 전화 : 118", "- URL : http://privacy.kisa.or.kr/"],
  },

  {
    label: "정보보호마크 인증위원회",
    value: ["- 전화 : 02-550-9531", "- URL : http://www.eprivacy.or.kr/"],
  },
  {
    label: "대검찰청 인터넷범죄수사센터",
    value: ["- 전화 : 02-3480-2000"],
  },
];

export const 하단_TYPE = [
  {
    label: "개인정보처리방침",
  },
  {
    label: "금감원등록대부업체조회",
  },
  {
    label: "제휴문의",
  },
];

export const 리뷰_TYPE = [
  {
    name: "김**님",
    text: "배려와 친절한 상담에 감사드립니다. 고금리를 저금리로 갈아탈 수 있게 안내해주셔서 그나마 부담 많이 덜게 될 것이라는 맘이 듭니다",
    tag: ["#통대환", " #저금리대환", " #이자", " #고금리"],
  },
  {
    name: "이**님",
    text: "대출이 필요해서 연락드렸는데 금리비교 잘해주시고 유리한쪽으로 친절히 상담해주셔서 대출잘받았습니다. 너무 감사합니다",
    tag: ["#금리비교", " #대출", " #저금리"],
  },
  {
    name: "정**님",
    text: "혹시나 또 대출될까 싶어 문의드렸는데 역시나 친절하게 도와주셨어요. 정말 감사드립니다",
    tag: ["#한도", " #재대출", " #추가담보대출", " #후순위담보대출"],
  },
  {
    name: "최**님",
    text: "가압류 근저당 설정되있는 빌라에 담보대출 찾기가 많이 힘들었는데 알게되서 빠르게 담보대출을 진행했네요ㅎㅎ감사합니다^^",
    tag: ["#압류", " #가압류", " #경매취하", " #연체", " #빌라담보대출"],
  },
  {
    name: "김**님",
    text: "약정서 때문에 진행이 안되었는데 덕분에 안전하게 갈아타기를 해서 살았어요",
    tag: ["#아파트담보대출", " #아파트매매잔금", " #대출갈아타기"],
  },
  {
    name: "남**님",
    text: "저금리 대환도 해주신다하셨는데 정말 3개월뒤 연락을 주셨네요! 덕분에 저금리 대환까지 해주셔서 정말 감사합니다! 번창하세요",
    tag: ["#투기지역", " #저금리대환 ", " #추가대출", " #후순위담보대출"],
  },
  {
    name: "유**님",
    text: "이전에도 타사업체와 상담을 많이 했지만 이제는 바로 연락드립니다! 대출에 고민 많으신분들께서는 추천드려요 후회없으실거세요^^",
    tag: ["#아파트담보대출", " #추가담보대출", " #한도비교", " #금리비교"],
  },
  {
    name: "김**님",
    text: "친절함에 감사에 또 감사드려요. 힘들게 여기저기 알아봐주시고 신경써주셔서 정말 감사합니다 담에 또 도움부탁드려요",
    tag: ["#담보대출", " #한도비교", " #금리비교"],
  },
  {
    name: "이**님",
    text: "기존에 집을 사면서 받았던 대출금리가 좋아서 두고 추가대출을 받았으면 했는데, 다른데서 알아본 것보다 좋은 조건을 찾아주셨어요.",
    tag: ["#사업자담보대출", " #추가대출", " #후순위대출"],
  },
  {
    name: "김**님",
    text: "배우자와 공동명의라 대출 받기가 쉽지 않았는데 지분대출도 가능하네요. 참 감사합니다^^",
    tag: ["#지분대출", " #지분담보대출", " #공동소유", " #배우자모르게"],
  },
];

export const Titles_PC_TYPE = [
  {
    key: 1,
    title: "주택담보대출",
  },
  {
    key: 2,
    title: "자동차담보대출",
  },
  {
    key: 3,
    title: "신용회생자대출",
  },
  {
    key: 4,
    title: "기타 대출서비스",
  },
];

export const Titles_MO_TYPE = [
  {
    key: 1,
    title: "주택담보",
  },
  {
    key: 2,
    title: "자동차담보",
  },
  {
    key: 3,
    title: "신용회생자",
  },
  {
    key: 4,
    title: "기타대출",
  },
];

export const 탭메뉴_TYPE = [
  {
    key: 1,
    subTitle: "주택담보대출",
    img: "/home_m.png",
    imgalt: "주택_이미지",
    oklabel: [
      "당일대출",
      "지분담보대출",
      "세입자미동의",
      "경매취하",
      "무직,연체자,신용 미등재",
    ],
    nolabel: [],
  },
  {
    key: 2,
    subTitle: "자동차담보대출",
    img: "/car_m.png",
    imgalt: "자동차_이미지",
    oklabel: [
      "당일대출",
      "전액 할부 차량도",
      "기존 대출 있어도",
      "무직자, 신용회복, 연체중",
      "공동소유",
    ],
    nolabel: [],
  },
  {
    key: 3,
    subTitle: "신용회생자대출",
    img: "/revive_m.png",
    imgalt: "회생_이미지",
    oklabel: [
      "회생,회복 변제금 0회차",
      "타인명의 휴대폰 사용자",
      "주부,프리랜서,재회생자",
    ],
    nolabel: ["소득제한", "중도상환수수료"],
  },
  {
    key: 4,
    subTitle: "기타 대출서비스",
    img: "/etc_m.png",
    imgalt: "기타_이미지",
    oklabel: ["토지,상가 담보대출", "무설정 아파트론", "임대보증금대출", "사업자 대출", "무직자 대출"],
    nolabel: [],
  },
];
