import React from "react";
import useScreen from "./hooks/useScreen";
import TopBannerM from "./components/TopBanner/Mobile/index";
import TabMenuM from "./components/TabMenu/Mobile/index";
import SliderWrapperM from "./components/SliderWrapper/Mobile/index";
import ContentM from "./components/Content/Mobile/index";
import ReviewM from "./components/Review/Mobile/index";
import FooterM from "./components/Footer/Mobile/index";
import styled from "styled-components";
import TopBanner from "./components/TopBanner/Default/index";
import SliderWrapper from "./components/SliderWrapper/Default/index";
import TabMenu from "./components/TabMenu/Default/index";
import Content from "./components/Content/Default/index";
import Review from "./components/Review/Default/index";
import Footer from "./components/Footer/Default/index";

const MoLinkContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  width: 100%;
  height: 60px;
`;

const Phone = styled.button`
  width: 50%;
  height: 60px;

  font-size: 16px;
  color: #fff;

  background: #6db236;
  border: none;

  img {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 360px) {
    img {
      width: 20px;
      height: 20px;
    }
    font-size: 18px;
  }
`;
const Kakao = styled.button`
  width: 50%;
  height: 60px;

  font-size: 16px;
  color: #333;

  background: #f9e000;
  border: none;

  img {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 360px) {
    img {
      width: 20px;
      height: 20px;
    }
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  display: flex;

  gap: 8px;

  align-items: center;
  justify-content: center;

  padding: 0 20px;
  font-family: Pretendard;

  font-weight: 500;

  @media screen and (max-width: 360px) {
    padding: 0 10px;
  }
`;

const Container = styled.div`
  width: 768px;

  margin: 0 auto;
`;

const ImgReview = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;
const App = () => {
  const { isMobile } = useScreen();

  const 전화상담_신청 = () => {
    window.open("tel:1644-2824", "_blank", "noopener, noreferrer");
  };

  const 카톡상담_신청 = () => {
    window.open(
      "https://pf.kakao.com/_xgAufxb/chat",
      "_blank",
      "noopener, noreferrer"
    );
  };

  return isMobile ? (
    <>
      <TopBannerM />
      <SliderWrapperM />
      <TabMenuM />
      <ContentM />
      <ReviewM />
      <FooterM />
      <MoLinkContainer>
        <Phone onClick={전화상담_신청}>
          <Wrapper>
            <img src="/phone.png" alt="전화_이미지" />
            <span>1644-2824</span>
          </Wrapper>
        </Phone>
        <Kakao onClick={카톡상담_신청}>
          <Wrapper>
            <img src="/kakao.png" alt="카카오톡_이미지" />
            <span>카카오톡 문의</span>
          </Wrapper>
        </Kakao>
      </MoLinkContainer>
    </>
  ) : (
    <>
      <Container>
        <TopBanner />
        <SliderWrapper />
        <TabMenu />
        <Content />
        <ImgReview>
          <img src="/review.png" alt="리뷰_이미지" />
        </ImgReview>
      </Container>
      <Review></Review>
      <Footer></Footer>
    </>
  );
};

export default App;
