import React from "react";

import { Titles_MO_TYPE, 탭메뉴_TYPE } from "../../../contants/defines";

import * as S from "./styles";

const TabMenuContainer = () => {
  const [activeTab, setActiveTab] = React.useState(1);

  const handleTabClick = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <S.Container>
        {Titles_MO_TYPE.map(({ key, title, subTitle }, index) => (
          <>
            <S.TabMenu
              key={index}
              active={activeTab === key}
              onClick={() => handleTabClick(key)}
            >
              {title}
            </S.TabMenu>
          </>
        ))}
      </S.Container>

      {탭메뉴_TYPE.map(
        ({ key, subTitle, img, imgalt, oklabel, nolabel }, index) => {
          const select = activeTab === key;
          return (
            <>
              {select && (
                <>
                  <S.SubTitle key={index}>{subTitle}</S.SubTitle>

                  <S.SubImg>
                    <img src={img} alt={imgalt} />
                  </S.SubImg>
                  <S.ContentWrapper>
                    <S.ContentContainer>
                      <S.ContentLabel>
                        {oklabel.map((label, labelIndex) => (
                          <S.LabelWrapper>
                            <img src="/check_m.png" alt="체크_이미지" />
                            <span key={labelIndex}>{label}</span>
                            <img src="/ok_m.png" alt="ok_이미지" />
                          </S.LabelWrapper>
                        ))}
                        {nolabel.map((label, labelIndex) => (
                          <S.LabelWrapper>
                            <img src="/check_m.png" alt="체크_이미지" />
                            <span key={labelIndex}>{label}</span>
                            <img src="/no_m.png" alt="no_이미지" />
                          </S.LabelWrapper>
                        ))}
                      </S.ContentLabel>
                    </S.ContentContainer>
                  </S.ContentWrapper>
                </>
              )}
            </>
          );
        }
      )}
    </>
  );
};

export default TabMenuContainer;
