import React from "react";
import * as S from "./styles";

const index = () => {
  return (
    <S.Banner>
      <S.Logo>
        <img src="/creditmall_logo.png" alt="크레디몰_로고" />
      </S.Logo>
      <S.Text>
        <p>대부중개 2020-서울영등포-2087</p>
      </S.Text>
    </S.Banner>
  );
};

export default index;
