import styled from "styled-components";

export const Banner = styled.div`
  width: calc(100%-40px);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

export const Logo = styled.div`
  img {
    width: 88px;
    height: 22px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
