import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-top: 40px;
`;

export const fadeInUp = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const TabMenu = styled.button`
  width: 25%;
  border: none;
  border-bottom: ${(props) => (props.active ? "2px solid #a3ba1c" : "none")};
  font-size: 16px;
  background-color: #fff;
  color: ${(props) => (props.active ? "#a3ba1c" : "#9BA1AC")};
  font-weight: 600;
  font-family: Pretendard;
  cursor: pointer;
`;

export const SubTitle = styled.div`
  color: #a3ba1c;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin: 40px 0;
`;

export const SubImg = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: 224px;
    animation: ${fadeInUp} 0.7s ease-in-out;
  }
`;

export const ContentLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  img {
    width: 30px;
    height: 20px;
  }
`;

export const ContentWrapper = styled.div`
  /* padding-left: 50px; */
  padding: 20px 0;
  background: #f8f9fb;
  border-radius: 20px;
  width: 50%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  margin: 0 0 16px 32px;
  font-family: Pretendard;
  font-weight: 500;

  img {
    height: 14px !important;
  }
`;

export const Test = styled.div`
  position: fixed;
  z-index: 1;
  overflow: hidden;

  display: flex;

  flex-direction: column;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-left: 848px;
`;

export const TestPhone = styled.button`
  display: flex;

  flex-direction: column;

  gap: 12px;
  border-bottom: 1px solid #ddd !important;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;

  font-size: 16px;
  font-weight: 500;
  color: #333;

  cursor: pointer;

  background-color: #fff;
  border: none;

  img {
    width: 32px;
    height: 32px;
  }
`;
export const TestKakao = styled.button`
  display: flex;

  flex-direction: column;

  gap: 12px;

  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;

  font-size: 16px;
  font-weight: 500;
  color: #333;

  cursor: pointer;

  background-color: #fff;
  border: none;

  img {
    width: 32px;
    height: 32px;
  }
`;
