import styled from "styled-components";

export const Banner = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  height: 60px;
`;

export const Logo = styled.div`
  img {
    width: 88px;
    height: 22px;
  }
`;
export const Text = styled.div`
  font-size: 16px;
  font-family: Pretendard;
  font-weight: 500;
`;
