import React from "react";

const useScreen = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const isMobile = React.useMemo(() => screenWidth <= 1100, [screenWidth]);

  return { screenWidth, isMobile };
};

export default useScreen;
