import React from "react";

import { 신속_정확_믿음_TYPE } from "../../../contants/defines";
import * as S from "./styles";

const index = () => {
  return (
    <>
      {/* <S.Container>
        <button>
          <p>사업자 최대 95%까지 직장인,무직자 최대 90%까지</p>
        </button>
      </S.Container> */}
      <S.ContainerContent>
        {신속_정확_믿음_TYPE.map(({ title, label }, index) => (
          <S.Item>
            <S.SubTitle>{title}</S.SubTitle>
            <div style={{ color: "#6D7582", paddingBottom: "5px" }}>
              {label[0]}
            </div>
            <div style={{ color: "#6D7582" }}>{label[1]}</div>
          </S.Item>
        ))}
      </S.ContainerContent>
    </>
  );
};

export default index;
