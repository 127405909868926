import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0 20px 0;
  button {
    border: 1px solid #bec3cc;
    width: calc(100% - 40px);
    background-color: #fff;
    margin: 0 auto;
    border-radius: 80px;
    font-size: 20px;
    font-weight: 700;
    color: #6d7582;
    p {
      line-height: 10px;
    }
  }
  font-family: Pretendard;
`;

export const ContainerContent = styled.div`
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0 40px;
`;

export const Item = styled.div`
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
`;
export const Title = styled.div`
  color: #a3ba1c;
  font-size: 32px;
  font-weight: 400;
  font-family: JalnanGothic;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-weight: 400;
  padding: 20px 0 10px;
  color: #6d7582;
  font-family: Pretendard;
`;
export const SubLabel = styled.div`
  padding-bottom: 5px;
`;
