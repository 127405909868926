import React from "react";

import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { 리뷰_TYPE } from "../../../contants/defines";
import * as S from "./styles";
const Review = ({ children, className }) => {
  const settings = React.useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: "linear",
    }),
    []
  );

  return (
    <>
      <S.Container>
        <img src="/review_m.png" alt="리뷰_이미지" />
      </S.Container>

      <S.SliderWrapper>
        <S.SliderTitle>고객후기</S.SliderTitle>
        <S.SliderContainer>
          <Slider {...settings}>
            {리뷰_TYPE.map(({ name, text, tag }, index) => {
              return (
                <S.ItemContainer key={index}>
                  <S.Item>
                    <S.Name>
                      <img src="/review_item_m.png" alt="리뷰_개인이미지" />
                      <S.NameDetail>{name}</S.NameDetail>
                    </S.Name>
                    <S.Text>{text}</S.Text>
                    <S.Tag>{tag}</S.Tag>
                  </S.Item>
                </S.ItemContainer>
              );
            })}
          </Slider>
        </S.SliderContainer>
        <S.SliderDetail>
          <p>이벤트성 후기글로</p>
          <p>현재는 이벤트가 종료되었습니다</p>
        </S.SliderDetail>
      </S.SliderWrapper>
    </>
  );
};

export default Review;
