import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  background: #6d7582;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  padding: 40px 20px 80px;
`;

export const Logo = styled.div`
  img {
    width: 92px;
    height: 22px;
  }
`;

export const Label = styled.button`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 12px;
  border: none;
  background: #6d7582;
  text-align: start;
`;

export const FooterContent = styled.div`
  border-bottom: 1px solid #fff;
  padding-bottom: 40px;
  color: #bec3cc;
  word-break: keep-all;
`;
export const FooterDetail = styled.div`
  padding-top: 40px;
  color: #bec3cc;
  word-break: keep-all;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;

  width: 100%;
  height: 100vh;

  background-color: #fff;
`;

export const TopTerms = styled.div`
  position: relative;

  display: flex;

  justify-content: center;

  width: 100%;
  height: 50px;
  margin-top: 20px;
`;
export const TopTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 0;

  margin-right: 20px;

  cursor: pointer;
`;
export const MiddleTerms = styled.div`
  width: calc(100% - 40px);
  max-height: 90vh;
  padding-bottom: 30px;
  margin: 0 auto;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const Items = styled.div`
  margin: 10px 0;

  font-size: 15px;
  font-weight: 500;
`;
export const ItemTitle = styled.div``;
export const ItemDetail = styled.div``;
