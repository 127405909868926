import React from "react";

import { 개인정보처리_TYPE } from "../../../contants/defines";
import { 하단_TYPE } from "../../../contants/defines";
import * as S from "./styles";

const Footer = () => {
  const [visible_개인정보처리, setVisible_개인정보처리] = React.useState(false);
  const 개인정보처리_visible = ({ label }) => {
    if (label === "개인정보처리방침") {
      setVisible_개인정보처리(() => !visible_개인정보처리);
      return;
    }
    if (label === "금감원등록대부업체조회") {
      window.open(
        "https://fines.fss.or.kr/fines/plis/moneyLenderSearch/MoneyLenderSearch.getMoneyLenderList.do",
        "_blank",
        "noopener, noreferrer"
      );
      return;
    }
  };

  const closeModal = () => {
    setVisible_개인정보처리(() => !visible_개인정보처리);
  };
  return (
    <>
      <S.Container>
        <S.Wrapper>
          <S.Logo>
            <img src="/footer_m_logo.png" alt="로고" />
          </S.Logo>
          <div
            style={{
              padding: "20px 0 40px",
              display: "flex",
              flexDirection: "column",
            }}
          >

            {하단_TYPE.map(({ label }, index) => {
              return (
                <S.Label
                  onClick={() => {
                    개인정보처리_visible({ label });
                  }}
                  key={index}
                >
                  {label}
                </S.Label>
              );
            })}
          </div>
          <S.FooterContent>
            <div style={{ paddingBottom: "20px" }}>
              대표번호 : 1644-2824 · 상호 : 크레딧몰 대부중개 · 대표 : 박병주 ·
              사업자등록번호 : 113-86-65453 대부중개업 등록번호 : 110111-4992099
            </div>
            <div>
              등록번호 : 2020-서울영등포-2087 · 등록도시명칭 : 영등포구청
              지역경제과 (02-2670-3418) 주소 : 서울특별시 영등포구 영신로38길
              18, 418호
            </div>
          </S.FooterContent>

          <S.FooterDetail>
            <div style={{ paddingBottom: "20px" }}>
              ※ 금리 : 연20%이내(연체금리는 대출금리 +3%p이내, 최대
              연20%이내)채무의 조기상환 조건 및 부대비용없음. 단, 담보대출은
              최대 3%이내 중도상환수수료 발생, 담보권설정비용 발생.중개수수료를
              요구하거나 받는 것은 불법입니다.과도한 빚은 당신에게 큰 불행을
              안겨줄 수 있습니다.대출 시 귀하의 신용등급이 하락할 수 있습니다.
            </div>
            <div>Copyright (C) 크레딧몰 Co.Ltd. All rights reserved</div>
          </S.FooterDetail>
        </S.Wrapper>
        {visible_개인정보처리 && (
          <S.Modal>
            <S.TopTerms>
              <S.TopTitle>이용약관</S.TopTitle>
              <S.CloseButton onClick={closeModal}>
                <img src="/close_button.png" alt="닫기버튼" />
              </S.CloseButton>
            </S.TopTerms>
            <S.MiddleTerms>
              {개인정보처리_TYPE.map(({ label, value }, index) => {
                return (
                  <S.Items key={index}>
                    <S.ItemTitle>{label}</S.ItemTitle>
                    {value.map((item, index) => (
                      <S.ItemDetail key={index}>{item}</S.ItemDetail>
                    ))}
                  </S.Items>
                );
              })}
            </S.MiddleTerms>
          </S.Modal>
        )}
      </S.Container>
    </>
  );
};

export default Footer;
