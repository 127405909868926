import styled from "styled-components";

export const SliderContainer = styled.section`
  position: relative;

  overflow-x: hidden;
`;

export const SliderTitle = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  padding: 60px 0 40px;
`;

export const SliderDetail = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 40px 0 20px;
  color: #9ba1ac;
  line-height: 5px;
`;
export const ItemContainer = styled.div`
  width: 95% !important;
  margin: 0 auto;
  height: 190px;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  margin-left: 20px;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const SliderWrapper = styled.div`
  height: 470px;
  background-color: #f8f8f8;
`;

export const Item = styled.div`
  padding: 0 20px;
`;

export const Name = styled.div`
  display: flex;
  padding-top: 20px;
  align-items: center;
  gap: 8px;
`;

export const Text = styled.div`
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 0 10px;
  word-break: keep-all;
`;

export const Tag = styled.div`
  color: #9ba1ac;
  text-align: start;
`;

export const NameDetail = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
