import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  background: #6d7582;
  margin: 0 auto;
`;
export const Wrapper = styled.div`
  padding: 40px 20px 80px;
`;
export const Logo = styled.div`
  display: flex;
  padding-bottom: 20px;
  img {
    width: 92px;
    height: 22px;
    padding-right: 20px;
  }
`;
export const Label = styled.button`
  color: #fff;
  font-size: 16px;
  font-weight: 700;

  border: none;
  background: #6d7582;
  text-align: start;
`;
export const FooterContent = styled.div`
  border-bottom: 1px solid #fff;
  padding-bottom: 40px;
  color: #bec3cc;
  word-break: keep-all;
`;
export const FooterDetail = styled.div`
  padding-top: 40px;
  color: #bec3cc;
  word-break: keep-all;
`;
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: flex;

  align-items: center;
  justify-content: center;

  background: rgb(0 0 0 / 50%);
`;
export const ModalContent = styled.div`
  width: 1000px;
  height: 580px;
  padding: 30px;

  cursor: default;

  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
`;
export const TopTerms = styled.div`
  position: relative;

  display: flex;

  justify-content: center;

  width: 100%;
  height: 50px;
`;
export const TopTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 0;

  cursor: pointer;
`;
export const MiddleTerms = styled.div`
  max-height: 500px;
  padding-bottom: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
export const TransParent = styled.div`
  position: fixed;
  z-index: 1;

  width: 100%;
  max-width: 1000px;
  height: 50px;
  margin-top: -50px;
  margin-right: auto;
  margin-left: auto;

  background-image: linear-gradient(
    to bottom,
    rgb(255 255 255 / 0%),
    rgb(255 255 255 / 100%)
  );
`;

export const Items = styled.div`
  margin: 10px 0;

  font-size: 15px;
  font-weight: 500;
`;
export const ItemTitle = styled.div``;
export const ItemDetail = styled.div``;
