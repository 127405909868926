import styled, { keyframes } from "styled-components";
export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-top: 40px;
`;

export const fadeInUp = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const TabMenu = styled.button`
  width: 25%;
  border: none;
  padding: 0;
  border-bottom: ${(props) => (props.active ? "2px solid #a3ba1c" : "none")};
  font-size: 16px;
  background-color: #fff;
  color: ${(props) => (props.active ? "#a3ba1c" : "#9BA1AC")};
  font-weight: 600;
  font-family: Pretendard;
`;

export const SubTitle = styled.div`
  color: #a3ba1c;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0 20px;
`;

export const SubImg = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -50px;
  img {
    width: 320px;
    height: 196px;
    animation: ${fadeInUp} 0.7s ease-in-out;
  }
`;

export const ContentLabel = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 16px;

  img {
    width: 35px !important;
    height: 24px !important;
  }
`;

export const ContentWrapper = styled.div``;

export const ContentContainer = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  background-color: #f8f9fb;
  border-radius: 20px;
  padding-top: 70px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 16px 32px;
  font-family: Pretendard;
  font-weight: 500;
  img:first-of-type {
    width: 30px !important;
    height: 20px !important;
  }
  img {
    width: 35px !important;
    height: 15px !important;
  }
`;
